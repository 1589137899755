/*header*/

.header {
    font-size: 14px;
    width: 100%; 
    height: 44px;
    background: #1b1b1b;
    border: none;
  }

.ant-btn-danger:hover, .ant-btn-danger:focus {
  color: #ff7875;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.ant-divider-horizontal {
  margin: 2px;
}

/*搜索框*/

.ant-input {
  font-size: 16px;
}
/*
.ant-input-wrapper.ant-input-group {
  font-size: 16px;
}
*/
.ant-btn.ant-input-search-button.ant-btn-primary {
  width: 80px;
}

.searchInput {
  /*font-size: 16px;*/
  width: 80%;
  padding-bottom: 2px;
  /* touch-action: auto; */
  /* pointer-events: none; */
}

/*汉字画*/
.hanzihuasection {
  /*width: 100%;*/
  position: relative;
}

.hanzihua {
  width: 100%; 
  height: auto; 
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  min-height: 100px;
  /*padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding: 10px; */
}

.searchImgLoadingSpin {
  position: absolute;
  top: 50%;
  left: 50%;
}

.linksection {
  padding-bottom: 10px;
}

/* .hanzihua:hover {
  transform: scale(1.3);
} 

/*汉字浏览按钮*/
.browsebuttons {
  color: #ffffff;
  background-color: lightgray;
  opacity: 0.8;
  vertical-align: middle;
  position: absolute;
  margin: 0 10px;
  top: 50%;
}

.left {
  left: 0;
  opacity: 0.8;
}

.right {
  right: 0;
  opacity: 0.8;
}

.singlehanzisectionByCat {
  position: relative;
}

.floatvideobutton {
  opacity: 0.8;
  position: absolute;
  vertical-align: middle;
  margin: 0 10px;
  top: 50%;
  right: 0;
  border: none;
}

/*
color: #ffffff;
background-color: lightgray;
*/

/* old browsebuttons
border-radius: 13px;
  border: none; 
  width: 35%;
  float: left;
  background-color: transparent; 
  border: none; 
  text-align: center;
  font-size: 18px!important;
  padding-bottom: 1px;

/*
  .bookbutton {
    background-color: #ffbb00;
    border-color: #ffbb00;
    box-shadow: 2px;
  }
  */

.panel {
  display: table;
  float: left;
  padding-bottom: 15px;
}

/*汉字视频*/
.video {
  /* padding: 3px;  */
  vertical-align: middle;
}
/*
old iframe css
  width: 32%; 
  padding-bottom: 75%;
  height: auto; 
  padding-left: 10px;
  padding-right: 5px; 
  float: left;
*/

/*汉字小图*/
.thumbnail {
  width: 32%; 
  border-radius: 2px;
  padding: 3px;
}

.thumbnail:hover {
  cursor: pointer;
}

/*分类汉字小图*/
.thumbbycat {
  width: 32%; 
  border-radius: 2px;
  padding: 3px;
}

.thumbbycat:hover {
  cursor: pointer;
}